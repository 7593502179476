.carousel-wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.carousel .slide {
  background: none;
}

.carousel .slide img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.carousel .control-arrow {
  opacity: 0;
}

.carousel .control-arrow:hover {
  opacity: 1;
}

.carousel.carousel-slider .control-arrow {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.carousel .control-next.control-arrow {
  right: 0;
}

.carousel .control-prev.control-arrow {
  left: 0;
}
