.App {
  text-align: center;
  /* background-image: linear-gradient(to bottom, #00b2ff, #0062ff, #1338a0); */
  /* background-color: #0062ff; */
  /* background-repeat: no-repeat; */
  /* background-color: #1338a0; */
  font-family: Montserrat;
}

.App-header {
  background-color: #FFFFFF;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 5px 10px 0 10px;
  border-radius: 8px;

  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
.App-header img {
  margin-top: 5%;
  /* margin-left: -5%; */
  width: 50%;
  max-width: 600px;
}
.info-texts {
  margin-top: 6%;
  /* margin-bottom: 5%; */
}
#nos-h1 {
  font-size: 1.5rem;
  margin-top: 5%;
  margin-bottom: 5%;
  font-weight: bold;
}

#App-content {
  /* min-height: 38vh; */
  margin: 5px 10px 0 10px;
  border-radius: 8px;
  padding-top: 10px;

  border-style: solid;
  border-width: 1px;
  border-color: gray;
}

h1,
h3 {
  color: #000;
  font-weight: bold;
}
.boxes {
  flex-direction: row;
  display: flex;
  /* margin-top: 10%; */
  justify-content: space-evenly;
}
#box {
  padding-top: 10px;
  width: 30%;
  height: 250px;
  border-radius: 8px;
}
#value-box {
  margin-top: 10;
  height: 34%;
  font-size: 4.8rem;
  font-weight: bold;
  color: #fff;
}
#legend-box {
  height: 33%;
  font-size: 4.4rem;
  font-weight: bold;
  color: #fff;
}
#box-text-footer {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 2.5rem;
  color: #fff;
}
#qrcode {
  padding-top: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#qrcode h1 {
  margin-left: 1%;
  font-size: 2rem;
}
#qrcode img {
  padding-top: 2%;
  width: 200px;
  height: 200px;
  border-radius: 20px 20px 20px 20px !important;
  justify-content: center;
  align-items: center;
}
#title-now{
  font-size: 4rem;
  margin-top: 0px;
}
#title-location{
  font-size: 4rem;
  margin-top: 0px;
}
#nos-h1{
  font-size: 4rem;
}
#nos-h3{
  font-size: 2.8rem;
}
#nos-h4{
  font-size: 2rem;
}
@media (max-width: 900px) {
  .App {
    text-align: center;
  }
  #container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .App-header {
    background-color: #FFFFFF;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    margin: 5px 10px 0 10px;
    color: #000;
    border-radius: 8px;
  }
  #App-content {
    min-height: 38vh;
    margin: 5px 10px 0 10px;
    border-radius: 8px;
    padding-top: 10px;
  }
  .App-header img {
    margin-top: 0%;
    min-width: 310px;
    /* display: none; */
  }
  .info-texts {
    margin-top: -2%;
    /* margin-bottom: 5%; */
  }
  h1,
  h3 {
    color: #000;
    font-weight: bold;
  }
  .boxes {
    flex-direction: row;
    display: flex;
    /* margin-top: 10%; */
    justify-content: space-evenly;
  }
  #box {
    padding-top: 2%;
    width: 30%;
    max-height: 150px;
    border-radius: 8px;
  }
  #dashboard-speedometer{
    margin-top: -2%;
  }

  #title-now{
    font-size: 1.5rem;
    margin-bottom: -10%;
  }
  #title-location{
    font-size: 1rem;
    margin-top: 0px;
  }
  #nos-h1{
    font-size: 1.5rem;
    padding: 5px !important;
  }
  #nos-h3{
    font-size:1.2rem;
    padding: 5px;
  }
  #nos-h4{
    font-size:1.2rem;
  }
  #value-box {
    margin-top: 5%;
    height: 34%;
    font-size: 2.8rem;
    font-weight: bold;
  }
  #legend-box {
    height: 33%;
    font-size: 2rem;
    font-weight: bold;
  }
  #box-text-footer {
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 1.2rem;
  }

  #qrcode {
    /* display: none; */
    padding-top: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #qrcode h1 {
    /* display: none; */
    margin-left: 1%;
    font-size: 1.5rem;
  }
  #qrcode img {
    display: none;
    padding-top: 2%;
    width: 200px;
    height: 200px;
    border-radius: 20px 20px 20px 20px !important;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 450px) {
  .App-header img {
    margin-top: 3%;
    min-width: 200px;
  }
  .info-texts {
    margin-top: 10px;
  }
  #qrcode h1 {
    /* display: none; */
    margin-left: 1%;
    font-size: 1.8rem;
  }
  #box-text-footer {
    font-size: 1.2rem;
  }
  #title-now {
    margin: 0 0 0 0;
  }
  #value-box {
    margin-top: 10%;
    height: 34%;
    font-size: 2.6rem;
    font-weight: bold;
  }
}
